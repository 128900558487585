
/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/

$(function() {

$("#typed").each(function() {
    var typed = new Typed('#typed', {
      stringsElement: '#typed-strings',
      typeSpeed: 80,
      backSpeed: 30,
      backDelay: 800,
      loop: true
    });
  });

});
